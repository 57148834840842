.form-control:focus {
    box-shadow: none;
    border-color:#ff7f50
}

.profile-button {
    background: #ff7f50;
    box-shadow: none;
    border: none
}

.profile-button:hover {
    background: #ff7f50
}

.profile-button:focus {
    background: #ff7f50;
    box-shadow: none
}

.profile-button:active {
    background: #d03d08;
    box-shadow: none
}

.back:hover {
    color: #ff7f50;
    cursor: pointer
}

.labels {
    font-size: 11px
}

.add-experience:hover {
    background:#ff7f50;
    color: #fff;
    cursor: pointer;
    border: solid 1px#ff7f50
}